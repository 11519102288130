import React from "react";
import './Home.css';

function Home(){

    return(
        <div className="home-title">
            <h1>Visual Communication</h1>
        </div>
    )
}

export default Home;